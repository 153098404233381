<script>
  export let hash;
  export let title;
</script>

<style>
  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
</style>

<iframe width="1280" height="720" src="https://www.youtube.com/embed/{hash}" {title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen/>