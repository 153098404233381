<script>
  import Header from "../lib/Header.svelte";
  import People from "../lib/People.svelte";
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  import { onMount } from "svelte";
  import Footer from "../lib/Footer.svelte";
  import YoutubeEmbed from "../lib/YoutubeEmbed.svelte";

  let tipFriendCloud;

  onMount(() => {
    tippy(tipFriendCloud, {
      content: 'A cloud for friends. A mix between Public and Private Cloud.',
      placement: 'bottom'
    });
  });

  /** @type {import('./$types').PageData} */
  export let data;
</script>

<svelte:head>
  <title>dark-firepit</title>
</svelte:head>

<main>
  <Header/>

  <div class="big neat-bg-container">
    <div class="neat-bg" style="transform: skewY(1deg)"></div>
    <pre>firepit</pre> is a friend group of sane<em>*</em> developers, mathematicians, artists
    and overall queer furries.
  </div>

  <p>
    This domain <em>(and associated server)</em> is used for hosting various
    <span class="has-tooltip" bind:this={tipFriendCloud}>friend cloud</span> services. <span title="among us">Among</span> those
    services is:
  </p>

  <el>
    <li><a href="https://nextcloud.oat.zone">Nextcloud</a></li>
    <li><a href="https://git.oat.zone/">Gitea</a></li>
    <li><a href="https://nitter.oat.zone/">Nitter</a></li>
  </el>

  <p>
    Alongside those, we also host some miscellaneous pages and services:
  </p>

  <el>
    <li><a href="/videos-of-all-time">Videos of all time</a></li>
    <li><a href="https://yugoslavia.best">yugoslavia.best</a></li>
    <li>
      Personal sites of <pre>firepit</pre> members:
      <a href="https://oat.zone">oat.zone</a>,
      <a href="https://aether.gay">aether.gay</a>,
      <a href="https://mayf.pink">mayf.pink</a>,
      <a href="https://wint0r.zone">wint0r.zone</a>
    </li>
  </el>

  <p>
    And occasionally, we fork or develop software:
  </p>

  <el>
    <li><a href="https://git.oat.zone/dark-firepit/dark-firepit-modpack">1.19.2 Minecraft modpack</a></li>
    <li><a href="https://git.oat.zone/dark-firepit/dotfiles">NixOS system configuration</a></li>
  </el>

  <p>
    We've also held a talk on the <a href="https://blanketcon.modfest.net/">BlanketCon modded convention</a>:
  </p>

  <YoutubeEmbed hash="LIpyC_q7JXw" title="An unintentional CTF, and how we solved it [BlanketCon 23 UNAUTHORIZED????]"/>

  <div class="big neat-bg-container center">
    <div class="neat-bg" style="transform: skewY(-1deg)"></div>
    But who are we, really?
  </div>

  <p class="center">
    We consist of 16 people, give or take:
  </p>

  <hr>

  <People/>

  <div class="big neat-bg-container center">
    <div class="neat-bg" style="transform: skewY(0.5deg)"></div>
    Why not reach out?
  </div>

  <p>
    <b>Tell us feedback about our services!</b> Tell us suggestions, issues, or just tell us about your day.
    We'll be sure to communally decide how to reply with the most informative, funniest or otherwise best
    response possible.
  </p>

  <p>
    Currently, you'll just have to use Jill's email, which would be <b>oat at [its domain]</b> (be sure
    to include <i>"firepit"</i> somewhere in the subject line). However, in the future we're looking to get
    more contact options. <i>Don't expect any requests to join the group to be taken seriously.</i>
  </p>

  <Footer remote={data.remote} hash={data.hash}/>
</main>