<script>
  export let remote;
  export let hash;
</script>

<footer class="center">
  <hr>

  <div class="center">
    <a href="https://stats.uptimerobot.com/ZyM4vi7Ao7" style="text-decoration: none">Status</a> • <a href="{remote.replace('.git', '')}/src/commit/{hash}" style="text-decoration: none">Source ({hash.slice(0, 7)})</a>
  </div>

  <i>Thank you for sus lel meem fof.</i><br>
  <img src="wawoo.png" alt="wawoo.png">
  <img src="cat.gif" alt="a jumping cat">
  <br>
  <img src="badges/badge1.gif" alt="badge that says 'best viewed with any browser, any os'">
  <img src="badges/badge2.gif" alt="aol instant messenger badge">
  <img src="badges/badge3.gif" alt="'written in the vi editor' badge">
  <img src="badges/badge4.gif" alt="'get quake now' badge">
  <a class="stealth-link" href="https://dark-firepit.cloud"><img src="badges/dark-firepit.png" alt="the dark-firepit badge"></a>
  <a class="stealth-link" href="https://oat.zone"><img src="badges/oat-zone.gif" alt="the oat.zone badge"></a>
</footer>